import {ApolloClient, NormalizedCacheObject} from '@apollo/client';
import {
  AnyEventObject,
  DoneInvokeEvent,
  EventObject,
  InvokeCreator,
  assign,
  createMachine,
  raise,
} from 'xstate';
import {
  readAccessToken,
  removeAccessToken,
  updateAccessToken,
} from './attendee-session-token';
import {fetchAttendee} from './fetch-attendee';
import type {GetAttendee} from './gql';
import {AccessCodeOverusedError, verifyAccessCode} from './verify-access-code';
import {
  InvalidEmailError,
  InvalidOpenLoginError,
  verifyOpenLogin,
} from './verify-open-login';
import {
  InvalidPublicPasscodeError,
  verifyPublicAttendee,
} from './verify-public-attendee';

/** Send an `Action` to the current machine when an invoke error occurrs */
const sendOnError = raise<Context, DoneInvokeEvent<Error>, AnyEventObject>;
/** Send an `Action` to the current machine when an invoke succeeds */
const sendOnSuccess = raise<
  Context,
  DoneInvokeEvent<AttendeeResult>,
  AnyEventObject
>;

const initialInvoke: InvokeCreator<Context, Action, AttendeeResult> = (
  context
) => {
  const initialAccessToken = readAccessToken(context.showId);
  if (initialAccessToken) {
    return fetchAttendee(context);
  } else {
    return Promise.reject(new Error('No stored attendee'));
  }
};

const pendingInvoke: InvokeCreator<Context, Action, AttendeeResult> = (
  context,
  event
) => {
  if (event.type === 'VERIFY') {
    return verifyAccessCode({
      context,
      showId: event.meta.showId,
      accessCode: event.meta.accessCode,
    });
  } else if (event.type === 'VERIFY_PUBLIC') {
    // This will call a new mutation for a different login type.
    // If this mutation returns data with a shape that consists of `id` and
    // `name` and `email` properties The shape of `AttendeeModel` in the
    // attendee-container could go unchanged which would mean none of the
    // downstream modules that are consuming the `useAttendee` hook would need
    // to change because all the properties they can read from the `useAttendee`
    // response already exist
    return verifyPublicAttendee({
      context,
      showId: event.meta.showId,
      passCode: event.meta.passCode,
      name: event.meta.name,
      moduleId: event.meta.moduleId,
      siteVersionId: event.meta.siteVersionId,
    });
  } else if (event.type === 'VERIFY_OPEN_LOGIN') {
    return verifyOpenLogin({
      context,
      agreementAnswer: event.meta.agreementAnswer,
      agreementText: event.meta.agreementText,
      email: event.meta.email,
      moduleId: event.meta.moduleId,
      name: event.meta.name,
      showId: event.meta.showId,
      siteVersionId: event.meta.siteVersionId,
    });
  } else {
    throw new Error(`Unknown event type ${event.type}`);
  }
};

export const ContainerMachine = createMachine<Context, Action, TypeState>(
  {
    id: 'AttendeeContainer',
    initial: 'init',
    predictableActionArguments: true,
    states: {
      init: {
        invoke: {
          id: 'initialize',
          src: initialInvoke,
          onDone: {
            actions: [
              sendOnSuccess((context, event) => {
                const action: Action = {
                  type: 'FETCH_SUCCESS',
                  meta: {attendee: event.data},
                };
                return action;
              }),
            ],
          },
          onError: {
            target: 'idle',
          },
        },
        on: {
          FETCH_SUCCESS: {
            target: 'success',
            actions: ['updateAttendee'],
          },
        },
      },
      idle: {
        on: {
          VERIFY: {
            target: 'pending',
            actions: [
              assign({
                about: (c, e) => e.meta.about,
                showId: (c, e) => e.meta.showId,
              }),
            ],
          },
          VERIFY_OPEN_LOGIN: {
            target: 'pending',
            actions: [
              assign({
                about: (c, e) => e.meta.about,
                showId: (c, e) => e.meta.showId,
              }),
            ],
          },
          VERIFY_PUBLIC: {
            target: 'pending',
            actions: [
              assign({
                about: (c, e) => e.meta.about,
                showId: (c, e) => e.meta.showId,
              }),
            ],
          },
        },
      },
      pending: {
        invoke: {
          id: 'perform',
          src: pendingInvoke,
          onDone: {
            actions: [
              sendOnSuccess((context, event) => {
                const action: Action = {
                  type: 'FETCH_SUCCESS',
                  meta: {attendee: event.data},
                };
                return action;
              }),
            ],
          },
          onError: {
            actions: [
              sendOnError((context, event) => {
                const action: Action = {
                  type: 'FETCH_FAILURE',
                  meta: {
                    reason: getReason(event.data),
                  },
                };
                return action;
              }),
            ],
          },
        },
        on: {
          FETCH_SUCCESS: {
            target: 'success',
            actions: ['updateAttendee'],
          },
          FETCH_FAILURE: {
            target: 'failure',
            actions: ['updateReason'],
          },
        },
      },
      success: {
        on: {
          VERIFY: {
            target: 'pending',
            actions: [assign({about: (c, e) => e.meta.about})],
          },
          VERIFY_OPEN_LOGIN: {
            target: 'pending',
            actions: [assign({about: (c, e) => e.meta.about})],
          },
          VERIFY_PUBLIC: {
            target: 'pending',
            actions: [assign({about: (c, e) => e.meta.about})],
          },
        },
      },
      failure: {
        entry: ['clearAttendeeId'],
        on: {
          RESET: 'idle',
          VERIFY: {
            target: 'pending',
            actions: [assign({about: (c, e) => e.meta.about})],
          },
          VERIFY_OPEN_LOGIN: {
            target: 'pending',
            actions: [assign({about: (c, e) => e.meta.about})],
          },
          VERIFY_PUBLIC: {
            target: 'pending',
            actions: [assign({about: (c, e) => e.meta.about})],
          },
        },
      },
    },
  },
  {
    actions: {
      clearAttendeeId: assign((context, event) => {
        if (event.type === 'FETCH_FAILURE') {
          // Clear from local storage
          removeAccessToken(context.showId);
          // Return `BaseContext`
          const result: BaseContext = {
            about: context.about,
            client: context.client,
            showId: context.showId,
          };
          return result;
        } else {
          return context;
        }
      }),
      updateAttendee: assign((context, event) => {
        if (event.type === 'FETCH_SUCCESS' && event.meta.attendee) {
          const attendee = event.meta.attendee;
          const token = attendee.chatTokens[0]?.token ?? '';
          const sessionToken = attendee.sessionToken;
          // Set session token in local storage
          if (typeof sessionToken === 'string') {
            updateAccessToken(context.showId, sessionToken);
          }
          const result: SuccessContext = {
            about: context.about,
            attendee,
            attendeeTags: event.meta.attendee.attendeeTags,
            client: context.client,
            sessionToken,
            showId: context.showId,
            token,
          };
          return result;
        } else {
          return context;
        }
      }),
      updateReason: assign((context, event) => {
        if (event.type === 'FETCH_FAILURE') {
          const result: FailureContext = {
            about: context.about,
            client: context.client,
            reason: event.meta.reason,
            showId: context.showId,
          };
          return result;
        } else {
          return context;
        }
      }),
    },
  }
);

const getReason = (error: Error): string | undefined => {
  switch (error.constructor) {
    case AccessCodeOverusedError: {
      return 'Access Code has been used too many times';
    }
    case InvalidEmailError:
      return 'Email address could not be validated';
    case InvalidOpenLoginError:
      return 'Credentials could not be provisioned';
    case InvalidPublicPasscodeError: {
      return error.message;
    }
    default: {
      return undefined;
    }
  }
};

/**
 * `AttendeeResult` is expressed this way to ensure the return types of the
 * related functions remain compatible, if they become incompatible the
 * `AttendeeResult` type will become `never` which will cause type errors.
 */
type AttendeeResult = Awaited<ReturnType<typeof fetchAttendee>> &
  Awaited<ReturnType<typeof verifyAccessCode>> &
  Awaited<ReturnType<typeof verifyOpenLogin>> &
  Awaited<ReturnType<typeof verifyPublicAttendee>>;

interface BaseContext {
  about?: string;
  client: ApolloClient<NormalizedCacheObject>;
  showId: string;
}

interface FailureContext extends BaseContext {
  reason?: string;
}

interface SuccessContext extends BaseContext {
  attendee: Attendee;
  attendeeTags: string[];
  token?: string;
  sessionToken?: string;
}

export type Context = SuccessContext | BaseContext;

type Action =
  | Event<'RESET'>
  | Event<
      'VERIFY',
      {
        about?: string;
        showId: string;
        siteVersionId?: string;
        accessCode: string;
      }
    >
  | Event<
      'VERIFY_PUBLIC',
      {
        about?: string;
        showId: string;
        siteVersionId?: string;
        passCode: string;
        moduleId: string;
        name: string;
      }
    >
  | Event<
      'VERIFY_OPEN_LOGIN',
      {
        about?: string;
        showId: string;
        siteVersionId?: string;
        agreementAnswer: boolean;
        agreementText: string;
        email?: string;
        moduleId: string;
        name: string;
      }
    >
  | Event<'FETCH_FAILURE', {reason?: string}>
  | Event<'FETCH_SUCCESS', {attendee: Attendee}>;

type TypeState =
  | {value: 'init'; context: BaseContext}
  | {value: 'idle'; context: BaseContext}
  | {value: 'pending'; context: BaseContext}
  | {value: 'success'; context: SuccessContext}
  | {value: 'failure'; context: FailureContext};

/**
 * An `Event` with a specific shape of data (`meta` key) and `type`.
 */
interface Event<
  Kind extends string,
  Data extends Record<string, unknown> = Record<string, never>,
> extends EventObject {
  /** @inheritdoc */
  type: Kind;
  /**
   * The shape of Data included with the event, if any.
   */
  meta: Data;
}

/**
 * `verifyAccessCode` represents the response after a guest has been
 * authenticated.
 */
type AccessCode = Exclude<GetAttendee['self'], null>;

/**
 * Add the `attendeeType` value to the `attendee` details in the `AccessCode`
 * response type to distinguish between the various authentication methods.
 */
type AttendeeDetails = AccessCode['attendee'] & {
  attendeeType: 'access-code' | 'open-login' | 'pass-code';
};

/** The properties pertaining to an Attendee */
export interface PublicAttendeeModel {
  sessionToken?: string;
  attendeeTags: string[];
  chatTokens: AccessCode['chatTokens'];
}

/** The properties pertaining to an Attendee */
export type AttendeeModel = AttendeeDetails & PublicAttendeeModel;

/** The Attendee data model, or possibly null if the attendee wasn't found  */
export type Attendee = AttendeeModel;
